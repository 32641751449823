// extracted by mini-css-extract-plugin
export var wrapper = "Interview-module--wrapper--c6358";
export var container = "Interview-module--container--2d573";
export var line = "Interview-module--line--4fafa";
export var player = "Interview-module--player--9e8cf";
export var interview = "Interview-module--interview--54fc2";
export var preview = "Interview-module--preview--4b48d";
export var description = "Interview-module--description--0925a";
export var btn = "Interview-module--btn--5a4b1";
export var title = "Interview-module--title--2c577";
export var text = "Interview-module--text--a8dca";
export var time = "Interview-module--time--c3955";