// extracted by mini-css-extract-plugin
export var wrapper = "Energy-module--wrapper--6cd03";
export var container = "Energy-module--container--52710";
export var energy = "Energy-module--energy--d9489";
export var card = "Energy-module--card--541be";
export var title = "Energy-module--title--a67e0";
export var image = "Energy-module--image--fb537";
export var middle = "Energy-module--middle--4216a";
export var info = "Energy-module--info--a3e5d";
export var description = "Energy-module--description--151ae";
export var arrow = "Energy-module--arrow--aa9bd";
export var text = "Energy-module--text--35f89";