import * as styles from './Stats.module.scss'
import { TabItem, TabList } from './TabList.tsx'
import weter from './assets/weter.svg'
import apple from './assets/apple.svg'
import google from './assets/google.svg'
import tesla from './assets/tesla.svg'

import React from 'react'
import { getTranslate } from 'react-localize-alias'

interface StatsProps {
  variant: 'weter' | 'gorod' | 'voda'
}

const variants = {
  weter: { label: 'W.E.T.E.R *', description: '', image: weter },
  gorod: { label: 'GOROD L.E.S *', description: '', image: weter },
  voda: { label: 'V.O.D.A *', description: '', image: weter },
}

export const Stats = ({ variant }: StatsProps) => {
  return (
    <section className={styles.stats}>
      <TabList>
        <TabItem label="Apple">
          <p className={styles.tabsText}>{getTranslate('chart.comment.apple', { missOnAlias: true })}</p>
          <img src={apple} alt="stats" />
        </TabItem>
        <TabItem label="Google">
          <p className={styles.tabsText}>{getTranslate('chart.comment.google', { missOnAlias: true })}</p>
          <img src={google} alt="stats" />
        </TabItem>
        <TabItem label="Tesla">
          <p className={styles.tabsText}>{getTranslate('chart.comment.tesla', { missOnAlias: true })}</p>
          <img src={tesla} alt="stats" />
        </TabItem>
        <TabItem label={variants[variant].label}>
          <p className={styles.tabsText}>{getTranslate('chart.comment.weter', { missOnAlias: true })}</p>
          <img src={variants[variant].image} alt="stats" />
        </TabItem>
      </TabList>
    </section>
  )
}
