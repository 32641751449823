/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'

import * as styles from './Interview.module.scss'
import player from './assets/player.svg'
import preview from './assets/preview.png'
import { Player } from '../../widgets'
import { Language } from '@enum'
import { AppContext } from '@components/App'
import { EmbedVideo } from '@UIKit'
import { getTranslate } from 'react-localize-alias'
import cn from 'classnames'

const interviews: Record<Language, string> = {
  ar: 'https://drive.google.com/file/d/1OWkhs7UYlNHtzGH6ucDBjq8C6dgXSG7v/preview',
  bn: 'https://drive.google.com/file/d/1WChtnO3vyZ5ptwOqU8M8JDEwVXMtRlhc/preview',
  en: 'https://drive.google.com/file/d/1QcusTnSNHIg6YF7g_I-vACKf3JzNtT5_/preview',
  fr: 'https://drive.google.com/file/d/1OvwQifceBMdxJsFW7oMks-TpKR4-1ivg/preview',
  hi: 'https://drive.google.com/file/d/1bZh3qMT7LD-9591tE5_I6irgQkzRVrx7/preview',
  id: 'https://drive.google.com/file/d/1TURV80WnPKF_iLRZ_uKeFjXXoOD4Y3Uc/preview',
  it: 'https://drive.google.com/file/d/1tsKEE-EKYGLKJLs2uQsIWt2OI6MiZQom/preview',
  ja: 'https://drive.google.com/file/d/1wyWIkqZFLT1UeKsqna4ZVSiSiJAx5EMB/preview',
  ko: 'https://drive.google.com/file/d/1fa0YUASEmnMymDzdYjLJTlz8SWRBlUgx/preview',
  pt: 'https://drive.google.com/file/d/11iuflkt0hH8uaaeYIe7uP6xmB3jFXXa5/preview',
  ru: 'https://drive.google.com/file/d/1ZV9jWp7KpesznEdIsGPZ22vzOB14kO16/preview',
  es: 'https://drive.google.com/file/d/1ePHSayzsIjTBOMwIG2Dg99Fo28sbhNWV/preview',
  vi: 'https://drive.google.com/file/d/1WCjjresTleuXKlf7WQ1tLGJoXs8vjv9m/preview',
  cn: 'https://drive.google.com/file/d/1nxUaFM2ruqnY-2GC4ivIDgUTtQNvXxjC/preview',
}

const Line = () => {
  return <hr className={styles.line} />
}

export const Interview = () => {
  const { langCode } = useContext(AppContext)
  const [play, setPlay] = useState(false)

  return (
    <section className={cn('container', styles.player)}>
      <div className={styles.interview}>
        {play ? (
          <EmbedVideo src={interviews[langCode || Language.Default]} />
        ) : (
          <div className={styles.preview} onClick={() => setPlay(true)}>
            <img className={styles.img} src={preview} alt="" />
            <div className={styles.description}>
              <button className={styles.btn}>
                <img src={player} alt="player" />
              </button>
              <div className={styles.title}>
                <h6 className={styles.text}>{getTranslate('landing.author.video.title')}</h6>
                <p className={styles.time}>33:50</p>
              </div>
            </div>
          </div>
        )}
        <Line />
      </div>
    </section>
  )
}
