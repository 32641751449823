/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { Root as RootOld } from '../../common/pages/voda'
import { Root } from '../../common/pages/new/voda'

const IndexPage = () => {
  return (
    <>
      {/* <RootOld /> */}
      <Root />
    </>
  )
}
export default IndexPage
