import React from 'react'

import * as styles from './Calculations.module.scss'
import bg from './assets/bg.png'
import document from './assets/document.svg'
import { getTranslate } from 'react-localize-alias'

export const Calculations = () => {
  return (
    <section className={styles.player}>
      <img className={styles.bg} src={bg} alt="" />
      <div className={styles.calc}>
        <h2 className={styles.title}>{getTranslate('calculations.title')}</h2>
        <div className={styles.file}>
          <img className={styles.img} src={document} alt="" />
          <p className={styles.download}>{getTranslate('calculations.button')}</p>
        </div>
      </div>
    </section>
  )
}
