// extracted by mini-css-extract-plugin
export var VideoPresentationBg = "Root-module--VideoPresentationBg--e229b";
export var video = "Root-module--video--0101b";
export var videoMap = "Root-module--videoMap--b2c60";
export var news = "Root-module--news--acf4f";
export var text = "Root-module--text--9a0a3";
export var title = "Root-module--title--e022f";
export var content = "Root-module--content--0ba0b";
export var form = "Root-module--form--aac5c";
export var wrapper = "Root-module--wrapper--1f738";
export var subtitle = "Root-module--subtitle--3be0c";
export var inputs = "Root-module--inputs--9cdb4";
export var links = "Root-module--links--333d9";
export var item = "Root-module--item--a0c6f";
export var icon = "Root-module--icon--e14d5";
export var social = "Root-module--social--8b2b1";