/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import cn from 'classnames'

import * as styles from './Modal.module.scss'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import CrossIcon from '@assets/images/weter/cross.inline.svg'
import { Typography } from '@UIKit'

interface ModalProps extends Omit<ReactModal.Props, 'className'> {
  className?: string
  keepMounted?: boolean
}

export const Modal: React.FC<ModalProps> = ({ className, children, keepMounted, isOpen, ...props }) => {
  useEffect(() => {
    const target = document.getElementById('main')

    if (target) {
      if (isOpen) {
        disableBodyScroll(target)
      } else {
        enableBodyScroll(target)
      }
    }
  }, [isOpen])

  return (
    <ReactModal
      className={cn(styles.modal, className, keepMounted && !isOpen && styles.hidden)}
      overlayClassName={cn(styles.overlay, keepMounted && !isOpen && styles.hidden)}
      preventScroll
      {...props}
      isOpen={keepMounted ? true : isOpen}
    >
      <CrossIcon className={styles.cross} onClick={props.onRequestClose} />
      <div className={styles.content}>
        {children}
      </div>
    </ReactModal>
  )
}
