// extracted by mini-css-extract-plugin
export var wrapper = "Hero-module--wrapper--9e625";
export var container = "Hero-module--container--f3393";
export var hero = "Hero-module--hero--7779a";
export var inner = "Hero-module--inner--375de";
export var info = "Hero-module--info--62b17";
export var logo = "Hero-module--logo--572ac";
export var title = "Hero-module--title--7396d";
export var line = "Hero-module--line--d0b75";
export var infoText = "Hero-module--infoText--f95ab";
export var card = "Hero-module--card--b6016";
export var date = "Hero-module--date--e77ee";
export var main = "Hero-module--main--79dd6";
export var mainText = "Hero-module--mainText--3e506";
export var bottom = "Hero-module--bottom--ad60a";
export var stats = "Hero-module--stats--2b888";
export var views = "Hero-module--views--84f7b";
export var likes = "Hero-module--likes--3a17c";
export var link = "Hero-module--link--dcd37";
export var navigation = "Hero-module--navigation--99960";
export var prev = "Hero-module--prev--0d979";
export var next = "Hero-module--next--3d019";
export var navLine = "Hero-module--navLine--1eebc";
export var img = "Hero-module--img--0a4ea";