import React from 'react'

import arrowRedLeft from './assets/arrowRedLeft.svg'
import arrowRedRight from './assets/arrowRedRight.svg'
import arrowGreenLeft from './assets/arrowGreenLeft.svg'
import arrowGreenRight from './assets/arrowGreenRight.svg'
import close from './assets/close.svg'
import done from './assets/done.svg'
import energy1 from './assets/energy1.png'
import energy2 from './assets/energy2.png'

import * as styles from './Energy.module.scss'
import { getTranslate } from 'react-localize-alias'

export const Energy = () => {
  return (
    <section className={styles.energy}>
      <div className={styles.card}>
        <h3 className={styles.title}>{getTranslate('solutions.title1')}</h3>
        <img className={styles.image} src={energy1} alt="energy" />
      </div>

      <div className={styles.middle}>
        <div className={styles.info}>
          <img className={styles.arrow} src={arrowRedLeft} alt="arrow" />
          <div className={styles.description}>
            <p className={styles.text}>{getTranslate('solutions.pros1')}</p>
            <img src={close} alt="close" />
          </div>
          <img className={styles.arrow} src={arrowRedRight} alt="arrow" />
        </div>

        <div className={styles.info}>
          <img className={styles.arrow} src={arrowGreenLeft} alt="arrow" />
          <div className={styles.description}>
            <p className={styles.text}>{getTranslate('solutions.cons1')}</p>
            <img src={done} alt="done" />
          </div>
          <img className={styles.arrow} src={arrowGreenRight} alt="arrow" />
        </div>
      </div>

      <div className={styles.card}>
        <h3 className={styles.title}>{getTranslate('solutions.title2')}</h3>
        <img className={styles.image} src={energy2} alt="energy" />
      </div>
    </section>
  )
}
