/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import * as styles from './Hero.module.scss'
import logo from './assets/logo.svg'
import dubai from './assets/dubai.png'

import React from 'react'
import { News } from '@graphql'
import dayjs from 'dayjs'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { getContent } from '@utils'
import { getTranslate } from 'react-localize-alias'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faEye, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

interface MainProps {
  selectedNews: News
  openNewsModal: () => void
  changeSelectedNews: {
    prevNews: () => void
    nextNews: () => void
  }
}

export const HeroVoda = ({ selectedNews, openNewsModal, changeSelectedNews }: MainProps) => {
  const { mainImage, publishedAt, title, shortDescription, viewsCount, likesCount } = selectedNews
  return (
    <section className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.info}>
            <div className={styles.logo}>
              <img src={logo} alt="Company Logo" />
            </div>
            <h1 className={styles.title}>{getTranslate('main.title')}</h1>
            {/* <hr className={styles.line} />
            <p className={styles.infoText}>{getTranslate('main.description')}</p> */}
          </div>

          <article className={styles.card}>
            <div className={styles.date}>
              <time dateTime="2023-12-06">{dayjs(publishedAt).format('DD MMMM')}</time>
              <time dateTime="16:24">{dayjs(publishedAt).format('YYYY')}</time>
            </div>
            <div className={styles.main}>
              {mainImage ? (
                <GatsbyImage className={styles.img} image={mainImage} alt="" />
              ) : (
                <StaticImage
                  src="../../../../../../assets/images/weter/newsPoster.jpg"
                  alt="WETER LOGO"
                  placeholder="blurred"
                  aspectRatio={1.77} // 16 / 9
                />
              )}
              <div className={styles.mainText}>{getContent(title)}</div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.stats}>
                <div className={styles.views}>
                  <FontAwesomeIcon icon={faEye} />
                  <div>{viewsCount}</div>
                </div>
                <div className={styles.likes}>
                  <FontAwesomeIcon icon={faHeart} />
                  <div>{likesCount}</div>
                </div>
              </div>
              <div className={styles.link} onClick={openNewsModal}>
                {getTranslate('landing.news.read')} <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
            <div className={styles.navigation}>
              <div className={styles.prev} onClick={changeSelectedNews.prevNews}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <div>{getTranslate('landing.main.news.prev')}</div>
              </div>
              <div className={styles.navLine} />
              <div className={styles.next} onClick={changeSelectedNews.nextNews}>
                <div>{getTranslate('landing.main.news.next')}</div>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}
